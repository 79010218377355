<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card :heading="page.title">
      <drag-people :data="params"></drag-people>
    </base-card>
  </v-container>
</template>
<script>
// import DragPeopleCourse from '../../components/forms/DragPeopleCourse.vue'
export default {
  // components: { DragPeopleCourse },
  data: () => ({
    page: {
      title: 'Administrar membros'
    },
    school: {},
    params: {
      rolesParamsSearch: '',
      roles: '',
      title: '',
      linkGetPeopleAdm: '',
      linkSaveAdm: ''
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school',
        exactPath: true
      }
    ]
  }),
  methods: {
    getSchoolData (organizationId) {
      this.$axios.get(`organizations/${organizationId}/schools/${this.$route.params.id}`)
        .then(res => {
          this.school = res.data
        }).catch(() => {
          const msg = 'Erro na conexão, tente novamente'
          this.$store.dispatch('alert', { color: 'red', msg })
        }).finally(() => {
          this.schoolFetch = true
          this.mountBreadcrumb()
        })
    },
    mountBreadcrumb () {
      this.breadcrumbs.push(
        {
          text: this.school?.data?.name || 'Sem nome definido',
          disabled: false,
          to: `/school/${this.$route.params.id}`
        },
        {
          text: this.params.title,
          disabled: false,
          to: `/school/list/${this.$route.params.id}/${this.$route.params.roles}`
        },
        {
          text: `Administrar ${this.params.title.toLowerCase()}`,
          disabled: true,
          to: '/school/people'
        }
      )
    }
  },
  created () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getSchoolData(this.id)
    const rolesParams = this.$route.params.roles
    if (rolesParams === 'coordinators') {
      this.page.title = 'Adicionar coordenadores à escola'
      this.params = {
        roles: 'SCHOOL_PEDAGOGICAL_COORDINATOR',
        title: 'Coordenadores',
        titleAdm: 'Coordenadores na escola',
        linkGetPeopleAdm: `/organizations/${this.id}/schools/${this.$route.params.id}/people?roles[]=SCHOOL_PEDAGOGICAL_COORDINATOR&per_page=200`,
        linkSaveAdm: `/organizations/${this.id}/schools/${this.$route.params.id}/people/sync`
      }
    } else if (rolesParams === 'principals') {
      this.page.title = 'Adicionar diretores à escola'
      this.params = {
        roles: 'PRINCIPAL',
        title: 'Diretores',
        titleAdm: 'Diretores na escola',
        linkGetPeopleAdm: `/organizations/${this.id}/schools/${this.$route.params.id}/people?roles[]=PRINCIPAL&per_page=200`,
        linkSaveAdm: `/organizations/${this.id}/schools/${this.$route.params.id}/people/sync`
      }
    }
  }
}
</script>
